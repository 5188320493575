$brand-color: #a11cfe;
$white: #fff;
$black: #000;

.heading {
  background: $brand-color;
  padding: 12px 0;
  color: $white;
  &.padding-tb-20 {
    padding: 20px 0px;
  }
  background-size: 100%;
  background-position: -10px;
  @media (max-width: 1024px) {
    background-position: bottom;
    background-repeat: no-repeat;
  }
  h1 {
    font-size: 20px !important;
    font-weight: 700;
    margin-bottom: 0px !important;
  }
  h2 {
    font-size: 24px;
  }
}

a {
  color: #9108f4 !important;
  text-decoration: none !important;
  &:hover {
    text-decoration: underline !important;
    font-weight: 800;
  }
}

.form {
  width: 62%;
}

.form-check-label {
  input[type="radio"]:checked + label:after {
    background: $brand-color !important;
  }
}

input[type="radio"]:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -3px;
  left: -1px;
  position: relative;
  background-color: $brand-color;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}


.gg-recaptcha {
  width: 304px !important;
  max-width: 304px !important;
  -ms-transform: scale(0.8, 0.8);
  -webkit-transform: scale(0.8, 0.8);
  transform: scale(0.8, 0.8);
  margin: 0px;
  padding: 0px;
}

.spinner {
  margin-left: 50%;
  margin-top: 100px;
}

.error-border {
  border: 1px solid #e90721 !important;
  box-sizing: border-box !important;
  box-shadow: inset 0 0 4px #e90721 !important;
}

.cursor {
  cursor: pointer;
}

.form-check-label {
  input[type="radio"]:checked + label:after {
    background: $brand-color !important;
  }
}

input[type="radio"]:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -3px;
  left: -1px;
  position: relative;
  background-color: $brand-color;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

.react-tel-input .invalid-number-message {
  position: absolute;
  z-index: 1;
  font-size: 13px;
  left: 3px !important;
  top: 40px !important;
  background: #fff;
  padding: 0 2px;
  color: #de0000;
}

.ww-attachment__file {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    color: #000;
    margin-bottom: 1em;
    background-color: rgba(133,255,255,.0902);
    padding: 12px;
    border: 1px solid rgba(64,249,249,.14902);
    border-radius: .5em;
    max-width: 38em;
}

.card-requestType {
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.18);
  background: #fff;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    box-shadow: 1px 2px 6px $brand-color !important;
  }
}

.required::before { content:" *"; color: red; font-weight: bold; float: right;}

.label-text {
  font-size: 18px;
}

.submit-request{
  background: $brand-color !important;
  border-color: $brand-color !important;
  &:hover{
    background: #7210BC !important;
    border-color: #7210BC !important
  }
}
